html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  background: #20262e;
  color: #fff;
  overflow: hidden;
}

.twitter {
  text-decoration: none;
  color: #1da1f2;
}

.container {
  padding: 24px;
  padding-bottom: 160px;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: scroll;
}

button {
  font-size: 12px;
  background-color: #e60028;
  border: none;
  outline: none;
}

input {
  color: #fff;
}

p {
  text-align: center;
  font-size: 10px;
}

a:hover {
  cursor: pointer;
}

.highlighted {
  background-color: #ffff00;
  color: #000;
}

th {
  position: relative;
}

th > .material-icons {
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 50%;
}

.tableContainer {
  position: relative;
}

footer {
  position: absolute;
  text-align: center;
  width: 100%;
  padding: 6px 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
/*# sourceMappingURL=index.4782b196.css.map */
